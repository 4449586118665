<template>
  <BaseDetailSimple
    :leave-guard="leaveGuard"
    :input-attributes="inputAttributes"
    :query-values="queryValues"
    entity-type="country"
    locale-section="pages.countries"
    field-section="countries"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "CountryDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: country
        country
        sv
        en
      `,
      inputAttributes: {
        country: { required: true },
        sv: { required: true },
        en: { required: true }
      }
    };
  }
};
</script>
